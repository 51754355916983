<template>
  <div>
    <ManageRegisterPhD />
  </div>
</template>
<script>
import ManageRegisterPhD from "@/components/ManageRegisterPhD/ManageRegisterPhD";
export default {
  components: {
    ManageRegisterPhD,
  },
  created() {},
};
</script>
