<template>
  <v-form>
    <v-card color="#F5F5F5" flat>
      <loading :active.sync="loading"></loading>
      <v-row align="center" justify="center">
        <v-col cols="12" md="6" sm="6">
          <span
            style="
              font-family: 'IBM Plex Sans Thai';
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 40px;
            "
            >สมัครเรียน (ป.เอก)</span
          >
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="6" sm="6" class="text-right">
          <v-btn
            @click="goToCreate()"
            color="#2AB3A3"
            dark
            style="height: 48px"
          >
            + เพิ่มสมัครเรียน (ป.เอก)
          </v-btn>
        </v-col>
        <v-col cols="12" class="mt-n2">
          <v-card class="pa-6" width="100%" flat>
            <v-row>
              <v-col cols="12" md="3" sm="3">
                <v-text-field
                  hide-details
                  v-model="search"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="ค้นหาประกาศสมัครเรียน"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" sm="3">
                <v-select
                  outlined
                  dense
                  :items="[
                    'สถานะทั้งหมด',
                    'เปิดใช้งาน',
                    'ปิดใช้งาน',
                    'ฉบับร่าง',
                  ]"
                  v-model="statusSearch"
                  @change="searchSelectStatus(statusSearch)"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-data-table
                  :headers="headersBrand"
                  :items="items"
                  :search="search"
                  disable-pagination
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                      :color="
                        item.status == 'เปิดใช้งาน'
                          ? '#ECFDF3'
                          : item.status == 'ฉบับร่าง'
                          ? '#F0F9FF'
                          : '#F2F4F7'
                      "
                      dark
                    >
                      <span
                        v-if="item.status == 'เปิดใช้งาน'"
                        style="color: #027a48"
                      >
                        {{ item.status }}
                      </span>
                      <span
                        v-if="item.status == 'ปิดใช้งาน'"
                        style="color: #344054"
                      >
                        {{ item.status }}
                      </span>
                      <span
                        v-if="item.status == 'ฉบับร่าง'"
                        style="color: #026aa2"
                      >
                        {{ item.status }}
                      </span>
                    </v-chip>
                  </template>
                  <template v-slot:[`item.img_url`]="{ item }">
                    <v-img
                      :src="item.img_url"
                      width="120px"
                      class="ma-1"
                    ></v-img>
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <v-icon @click="ViewNews(item)">mdi-content-copy</v-icon>
                    <v-icon @click="UpdateNews(item)">mdi-pencil</v-icon>
                    <v-icon @click="DeleteItem(item)">mdi-delete</v-icon>
                  </template>
                  <template
                    v-slot:body="props"
                    v-if="isWindowWidthGreaterThan768"
                  >
                    <draggable
                      :list="props.items"
                      tag="tbody"
                      @change="DragItems(props.items)"
                    >
                      <tr v-for="(item, index) in props.items" :key="index">
                        <td v-if="isWindowWidthGreaterThan768">
                          <v-icon small class="page__grab-icon">
                            mdi-arrow-all
                          </v-icon>
                        </td>
                        <td class="text-start">{{ item.sortindex }}</td>
                        <td class="text-start">{{ item.name_th }}</td>
                        <td class="text-center">
                          <v-chip
                            :color="
                              item.status == 'เปิดใช้งาน'
                                ? '#ECFDF3'
                                : item.status == 'ฉบับร่าง'
                                ? '#F0F9FF'
                                : '#F2F4F7'
                            "
                            dark
                          >
                            <span
                              v-if="item.status == 'เปิดใช้งาน'"
                              style="color: #027a48"
                            >
                              {{ item.status }}
                            </span>
                            <span
                              v-if="item.status == 'ปิดใช้งาน'"
                              style="color: #344054"
                            >
                              {{ item.status }}
                            </span>
                            <span
                              v-if="item.status == 'ฉบับร่าง'"
                              style="color: #026aa2"
                            >
                              {{ item.status }}
                            </span>
                          </v-chip>
                        </td>

                        <td class="text-start">
                          <v-row justify="center">
                            <v-icon @click="ViewNews(item)"
                              >mdi-content-copy</v-icon
                            >
                            <v-icon @click="UpdateNews(item)" class="mx-2"
                              >mdi-pencil</v-icon
                            >
                            <v-icon @click="DeleteItem(item)"
                              >mdi-delete</v-icon
                            >
                          </v-row>
                        </td>
                      </tr>
                    </draggable>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import moment from "moment";
export default {
  components: {
    draggable,
    Loading,
  },
  data() {
    return {
      statusSearch: "สถานะทั้งหมด",

      tab: null,
      typeCheck: 0,

      headersBrand: [],
      headersBrand1: [
        { text: "", value: "", align: "start" },
        { text: "ลำดับ", value: "sortindex", align: "start" },
        { text: "ชื่อหัวข้อ (ไทย)", value: "name_th", align: "start" },
        { text: "สถานะ", value: "status", align: "center" },
        // { text: "ภาคปกติ/ภาคพิเศษ", value: "semesterCourse", align: "start" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      headersBrand2: [
        { text: "ลำดับ", value: "sortindex", align: "start" },
        { text: "ชื่อหัวข้อ (ไทย)", value: "name_th", align: "start" },
        { text: "สถานะ", value: "status", align: "center" },
        // { text: "ภาคปกติ/ภาคพิเศษ", value: "semesterCourse", align: "start" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      dialog: false,
      isEdit: false,
      items: [],
      itemsAll: [],
      search: "",
      sortindex: 0,
      user: "",
      loading: false,
      imgUrl: "",
      e1: 0,
    };
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
    this.getAll();
    // this.getAll1();
    document.title = "สมัครเรียน (ป.เอก) - GSCM Management System";
  },

  mounted() {
    window.scrollTo(0, 0);

    if (this.isWindowWidthGreaterThan768) {
      this.headersBrand = this.headersBrand1;
    } else {
      this.headersBrand = this.headersBrand2;
    }
  },

  methods: {
    async DragItems(val) {
      this.loading = true;
      console.log("DragItems", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };

      for (let i in val) {
        val[i].index = parseInt(i) + 1;
      }

      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/enrolls/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      const response1 = await this.axios.get(
        `${process.env.VUE_APP_API}/enrolls?levelId=${13}`,
        auth
      );
      console.log("findAllUpdateIndex", response1.data.data);
      this.items = response1.data.data;
      this.loading = false;
    },
    searchSelectStatus(val) {
      let AllItems = this.itemsAll;
      let items1 = AllItems.filter((x) => x.status === val);
      if (this.statusSearch == "สถานะทั้งหมด") {
        this.items = AllItems;
      } else {
        this.items = items1;
      }
    },
    convertDate(val) {
      return moment(val).format("MM/DD/YYYY hh:mm");
    },

    async getAll() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/enrolls?levelId=${13}`,
        auth
      );
      console.log("getAll", response.data.data);
      this.items = response.data.data;
      this.itemsAll = response.data.data;
      this.imgUrl = response.data.data.imgUrl;
      for (let i in this.items) {
        this.items[i].sortindex = parseInt(i) + 1;
      }
      this.loading = false;
    },
    async DeleteItem(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/enrolls/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAll();
        }
      });
    },
    goToCreate() {
      this.$router.push("CreateRegisterPhD");
    },
    UpdateNews(val) {
      localStorage.setItem("resigterPhD_Data", Encode.encode(val));
      this.$router.push("EditRegisterPhD");
    },

    async ViewNews(val) {
      this.loading = true;
      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("gscmAdmin"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/enrolls/${val.id}`,
        auth
      );
      console.log("getOne", response.data.data);
      delete response.data.data.id;
      let duplicateData = response.data.data;

      await duplicateData.imageEnrollAnnounces.forEach(async (element) => {
        delete element.enrollId;
        delete element.id;
      });
      await duplicateData.imageEnrollRegisters.forEach(async (element) => {
        delete element.enrollId;
        delete element.id;
      });
      console.log("duplicateData", duplicateData);

      const response1 = await this.axios.post(
        `${process.env.VUE_APP_API}/enrolls`,
        duplicateData,
        auth
      );
      console.log("response1", response1);

      console.log(response1.data.response_status);
      if (response1.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `คัดลอกสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.getAll();
        this.getAll1();
      } else {
        this.$swal.fire({
          icon: "error",
          text: response1.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      this.loading = false;
    },
  },
};
</script>